.split-container {
    display: flex;
    justify-content: space-between;
    height: 100vh;
}

.left {
    width: 65%;
    height: 100%;
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.about-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}

.about-container h1 {
    margin: 0 0 1.5rem 0;
}

.about-container p {
    margin: 0.8rem;
    font-size: 1.1rem;
}

.right {
    width: 35%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--accent2);
}

/* .right h1 {
    font-weight: 500;
    font-size: 2.3rem;
    margin: 1.5rem 0 0 0;
}

.right h3 {
    font-weight: 300;
    font-size: 1.3rem;
    margin: 0.3rem 0 2rem 0;
    color: var(--neutral-pop);
} */

@media (max-width: 860px) {
    .split-container {
        display: flex;
        flex-direction: column;
        height: 250vh;
    }

    .left {
        width: 100%;
        padding: 0;
    }

    .right {
        width: 100%;
    }

    .about-container {
        margin: 1rem 0 0 0;
    }

    .about-container h1 {
        margin: 0 0 0.5rem 0;
    }
    
    .about-container p {
        font-size: 0.9rem;
    }
}