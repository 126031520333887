.skills-container {
    width: 100%;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.list-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin: 2rem 0;
}

.skill-list ul {
    list-style: none;
    margin: 0.5rem 0;
    color: var(--main);
}

@media (max-width: 860px) {
    .skills-container {
        padding: 3rem;
        margin: 0;
    }

    .list-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 2rem 0;
    }

    h2 {
        font-size: 1.3rem;
    }

    .skill-list {
        width: 45%;
        margin: 1rem 0;
    }
}