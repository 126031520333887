.info-container h1 {
    font-weight: 500;
    font-size: 2.3rem;
    margin: 1.5rem 0 0 0;
}

.info-container h3 {
    font-weight: 300;
    font-size: 1.3rem;
    margin: 0.3rem 0 2rem 0;
    color: var(--neutral-pop);
}

.icon-container {
    width: 60%;
}

.icon-list {
    list-style: none;
    display: flex;
    padding-left: 0;
    width: 100%;
    font-size: 1.8rem;
    justify-content: space-around;
    align-items: center;
}

.icon-list a:any-link {
    color: var(--neutral-pop);
}

@media (max-width: 860px) {
    .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}