.portfolio-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 3rem 2rem;
}

.card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: 38%;
    margin: 3rem;
}

.card:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border-radius: 1rem;
}

.card-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 25%;
    background-color: var(--main);
    border-radius: 1rem 1rem 0 0;
    padding: 0.5rem 1rem;
}

.card-top a:any-link {
    color: var(--neutral-pop)
}

.card-middle {
    background-color: var(--neutral-pop);
    width: 100%;
    height: 55%;
    overflow: auto;
    padding: 1rem;
}

.stack-icons {
    color: var(--accent2);
    font-size: 1.8rem;
}

.card-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--neutral-pop);
    width: 100%;
    height: 25%;
    padding: 0.5rem 1rem 1rem 1rem;
    border-radius: 0 0 1rem 1rem;
}

.git-links a:any-link {
    color: var(--pop);
    font-size: 1.6rem;
}

.go-btn a:any-link {
    background-color: var(--pop);
    color: var(--neutral-pop);
    padding: 0.5rem;
    border-radius: 0.3rem;
}

@media (max-width: 860px) {
    .portfolio-container {
        height: 250vh;
    }

    .card-container {
        height: 100%;
    }

    .card {
        width: 80%;
        max-height: 16.5rem;
        margin: 1.5rem;
    }

    .card-top h2 {
        font-size: 1.2rem;
    }

    .card-middle p {
        font-size: 0.8rem;
    }

    .stack-icons {
        font-size: 1.4rem;
    }
}