.home-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 180vh;
}

.main {
    background-image: url('../background.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.overlay {
    background: rgba(65,66,62,0.3);
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main h1 {
    font-size: 6rem;
    font-weight: 800;
    color: var(--neutral-pop);
    z-index: 2;
}

.main h2 {
    font-size: 2.5rem;
    color: var(--pop);
    z-index: 2;
}

.main-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-start;
    width: 75%;
    height: 100%;
    z-index: 0;
}

.main-bottom {
    display: flex;
    justify-content: center;
    align-content: center;
    margin: 2rem;
    width: 100%;
    height: 25%;
    z-index: 0;
}

.brand {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    width: 70%;
    background-color: rgba(136,185,24,0.8);
    font-size: 1.3rem;
    font-weight:300;
    font-style: italic;
    padding: 0.5rem 8rem;
    border-radius: 5rem 0 5rem 0;
}

.brand p {
    margin: 1rem;
}

.highlight {
    color: var(--neutral-dk);
    font-weight: 700;
}

.portfolio-btn {
    background-color: var(--main);
    color: var(--neutral-pop);
    padding: 1rem;
    margin: 1rem;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem 0 1rem 0;
    transition: 0.3s;
}

.portfolio-btn:hover {
    background-color: var(--neutral-pop);
    color: var(--main);
}

@media (max-width: 860px) {
    .main {
        height: 40vh;
    }

    .main h1 {
        font-size: 2.8rem;
        width: 100%;
    }

    .main h2 {
        font-size: 1.8rem;
    }

    .brand {
        font-size: 1rem;
        padding: 1.2rem;
    }

    .brand p {
        margin: 0.5rem;
    }

    .portfolio-btn {
        -webkit-appearance: none;
        width: 55%;
        margin: 2rem 0 4rem 0;
    }

}