.contact-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0 3rem 0;
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 1rem 0;
}

input, textarea {
    border: none;
    width: 50%;
    margin: 0.8rem 0;
    padding: 0.5rem;
    font-family: 'Lato', sans-serif;
    border-radius: 0.3rem;
}

label {
    width: 50%;
}

input[type=text], input[type=email] {
    height: 1.8rem;
}

textarea {
    height: 5rem;
}

input[type=submit] {
    width: 20%;
    height: 2rem;
    background-color: var(--main);
    border-radius: 1rem;
    font-weight: 700;
    color: var(--neutral-pop);
    transition: 0.2s;
}

input[type=submit]:hover{
    background-color: var(--neutral-pop);
    /* border: 0.15rem solid var(--pop); */
    color: var(--pop);
    transition: 0.2s;
}

@media (max-width: 860px) {

    input[type=submit] {
        -webkit-appearance: none;
        width: 25%;
    }

    input, textarea, label {
        width: 70%;
    }
}


